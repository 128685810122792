import { mapGetters } from "vuex";

export default {
  head() {
    return {
      link: [
        {
          rel: "icon",
          type: "image/png",
          href: this.favicon
        }
      ]
    };
  },

  data() {
    return {
      favicon: "/favicon.png"
    };
  },

  computed: {
    ...mapGetters(["isLoggedIn"])
  },

  watch: {
    $route: {
      handler(to, from) {
        this.$store.dispatch("closeHeaderMenuImmediately");
        this.$store.dispatch("closeHeaderSubmenu");
        this.$store.dispatch("closeHeaderMobileSubmenu");
        this.$store.dispatch("closeModal");
      },
      immediate: true
    }
  },

  methods: {
    setDarkModeFavicon() {
      if (!process.client) return;

      if (
        window.matchMedia &&
        window.matchMedia("(prefers-color-scheme: dark)").matches
      ) {
        this.favicon = "/favicon-dark-mode.png";
      }
    }
  },

  async created() {
    if (this.isLoggedIn) {
      await this.$store.dispatch("setUserData");
      this.$liveChat.boot();
    }
  },

  mounted() {
    this.setDarkModeFavicon();
  }
};
