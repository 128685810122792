<template>
  <transition name="page">
    <main id="main">
      <CookieBar />
      <Nuxt />
    </main>
  </transition>
</template>


<script>
  import layoutMixin from '@/mixins/layoutMixin';
  import CookieBar from '@/components/global/CookieBar';

  export default {
    name: 'Quiz',

    mixins: [
      layoutMixin
    ],

    components: {
      CookieBar
    }
  }
</script>
