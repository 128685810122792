import { mapState, mapGetters } from "vuex";

import { truncate, getTitle, formatDays, formatMilitaryTime } from "@/helpers";

export default {
  data() {
    return {
      host: null
    };
  },

  computed: {
    ...mapState({
      locale: state => state.locale,
      globalData: state => state.globalData,
      pageTitle: state => state.meta.pageTitle,
      pageDescription: state => state.meta.pageDescription,
      pageImage: state => state.meta.pageImage,
      pageType: state => state.meta.pageType,
      productBrand: state => state.meta.productBrand,
      productPrice: state => state.meta.productPrice,
      seoMeta: state => state.meta.seoMeta
    }),
    ...mapGetters(["domain"]),
    siteName() {
      return this.$config.siteName;
    },
    url() {
      return 'https://' + this.domain + this.$route.path;
    },
    globalMeta() {
      return {
        metaTitle: this.globalData.meta_title,
        metaDescription: this.globalData.meta_description,
        metaKeywords: this.globalData.meta_keywords,
        metaImage: this.globalData.meta_image
      };
    },
    metaTitle() {
      if (this.seoMeta && this.seoMeta.metaTitle) {
        return getTitle(this.seoMeta.metaTitle);
      }

      if (this.pageTitle) {
        return getTitle(this.pageTitle);
      }
    },
    fullMetaTitle() {
      const siteName = this.globalMeta.metaTitle
        ? this.globalMeta.metaTitle
        : this.siteName;

      if (this.metaTitle) {
        return `${this.metaBrand ? this.metaBrand + ' - ': ''}${this.metaTitle} | ${siteName}`;
      }
      return (
        siteName +
        (this.globalData.site_tagline
          ? ` | ${this.globalData.site_tagline}`
          : "")
      );
    },
    metaDescription() {
      if (this.seoMeta && this.seoMeta.metaDescription) {
        return this.getMetaDescription(this.seoMeta.metaDescription);
      }

      if (this.pageDescription) {
        return this.getMetaDescription(this.pageDescription);
      }

      if (this.globalMeta.metaDescription) {
        return this.getMetaDescription(this.globalMeta.metaDescription);
      }
    },
    metaKeywords() {
      if (this.seoMeta && this.seoMeta.metaKeywords) {
        return this.seoMeta.metaKeywords;
      }

      if (this.globalMeta.metaKeywords) {
        return this.globalMeta.metaKeywords;
      }
    },
    metaImage() {
      if (this.seoMeta && this.seoMeta.metaImage) {
        return this.getMetaImage(this.seoMeta.metaImage);
      }

      if (this.pageImage) {
        if (this.pageType !== "product")
          return this.getMetaImage(this.pageImage);

        return this.pageImage;
      }

      if (this.globalMeta.metaImage) {
        return this.getMetaImage(this.globalMeta.metaImage);
      }
    },
    metaBrand() {
      return this.$prismic.asText(this.productBrand);
    },
    currency() {
      return this.locale == 'en-ca' ? 'CAD' : 'USD';
    }
  },

  methods: {
    getMetaDescription(value) {
      if (typeof value === "string") return value;

      if (value) return truncate(this.$prismic.asText(value), 140);
    },

    getMetaImage(image) {
      return image ? `${image.url}?w=1200&h=600` : null;
    },

    openingHours() {
      if (!this.studio) return null;

      return this.studio.hours.map(segment => {
        const day = formatDays(segment.days);
        const start = formatMilitaryTime(segment.start_time);
        const end = formatMilitaryTime(segment.end_time);
        return `${day} ${start}-${end}`;
      });
    }
  },

  head() {
    const head = {
      htmlAttrs: {
        lang: this.locale
      },
      title: this.fullMetaTitle,
      link: [
        {
          rel: "canonical",
          href: this.url
        }
      ],
      meta: [
        {
          hid: "title",
          name: "title",
          content: this.fullMetaTitle
        },
        {
          hid: "description",
          name: "description",
          content: this.metaDescription
        },
        {
          hid: "og:site_name",
          name: "og:site_name",
          content: this.siteName
        },
        {
          hid: "og:url",
          name: "og:url",
          content: this.url
        },
        {
          hid: "og:type",
          name: "og:type",
          content: this.pageType
        },
        {
          hid: "og:title",
          name: "og:title",
          content: this.fullMetaTitle
        },
        {
          hid: "og:description",
          name: "og:description",
          content: this.metaDescription
        },
        {
          hid: "og:image",
          name: "og:image",
          content: this.metaImage
        },
        {
          hid: "og:image:secure_url",
          name: "og:image:secure_url",
          content: this.metaImage
        },
        {
          hid: "twitter:card",
          name: "twitter:card",
          content: this.pageType
        },
        {
          hid: "twitter:title",
          name: "twitter:title",
          content: this.fullMetaTitle
        },
        {
          hid: "twitter:description",
          name: "twitter:description",
          content: this.metaDescription
        },
        {
          hid: "twitter:image",
          name: "twitter:image",
          content: this.metaImage
        }
      ]
    };

    if(this.metaKeywords) {
      head.meta.push({
        hid: "keywords",
        name: "keywords",
        content: this.metaKeywords
      });
    }

    if(this.pageType == 'product') {
      head.meta.push({
        hid: "og:price:amount",
        name: "og:price:amount",
        content: this.productPrice
      }, {
        hid: "og:price:currency",
        name: "og:price:currency",
        content: this.currency
      }, {
        hid: "twitter:label1",
        name: "twitter:label1",
        content: 'Price'
      },
      {
        hid: "twitter:data1",
        name: "twitter:data1",
        content: this.productPrice && `$${this.productPrice} ${this.currency}`
      },
      {
        hid: "twitter:label2",
        name: "twitter:label2",
        content: 'Brand'
      },
      {
        hid: "twitter:data2",
        name: "twitter:data2",
        content: this.metaBrand
      });
    }

    return head;
  },
};
