import {
  isSticky,
  getStickyElData,
  handleSticky,
  handleStickyState
} from '@/sticky';

export default {
  data() {
    return {
      shouldScroll: process.client ? window.innerWidth > 769 : false,
      events: ['load', 'scroll', 'resize'],
      parentHeight: null
    }
  },

  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.initSticky();
      }, 1000);
    });
  },

  destroyed() {
    if (this.shouldScroll) {
      this.events.forEach(event => {
        window.removeEventListener(event, handleSticky);
      });
    }
  },

  methods: {
    initSticky() {
      if (this.shouldScroll) {
        const stickyData = getStickyElData(this.$refs.stickyEl);
        
        if (!stickyData) return null
        
        this.events.forEach(event => {
          window.addEventListener(event, () => handleSticky(this, stickyData))
        });

        this.watchParent();
      }
    },

    watchParent() {
      const parent = this.$refs.stickyEl.parentNode;
      this.parentHeight = parent.getBoundingClientRect().height;

      const observer = new ResizeObserver(entries => {
        entries.forEach(entry => {
            if (this.parentHeight !== entry.contentRect.height) {
              const stickyData = getStickyElData(this.$refs.stickyEl);
              
              if (!stickyData) return null
              
              handleStickyState(window.scrollY, stickyData);
              this.parentHeight = entry.contentRect.height;
            }
        });
      });

      observer.observe(parent);
    }
  }
}
