import { mapActions } from 'vuex';

import { fetchQuizCallout, fetchQuestionsCallout } from '@/prismic/queries';

import { getTitle } from '@/helpers';

export default {
  data() {
    return {
      quizCallout: null,
      questionsCallout: null
    }
  },

  computed: {
    isReady() {
      return (this.quizCallout || this.questionsCallout || this.collection || this.product) ? true : false;
    },
    title() {
      switch (this.type) {
        case 'Quiz':
          return getTitle(this.quizCallout.quiz_callout_title);
        case 'Questions':
          return getTitle(this.questionsCallout.questions_callout_title);
        case 'Product':
          return getTitle(this.product.callout_subtitle) ? getTitle(this.product.title) : null;
        case 'Collection':
          return 'Collection';
      }
    },
    subtitle() {
      switch (this.type) {
        case 'Quiz':
          return this.quizCallout.quiz_callout_subtitle;
        case 'Questions':
          return this.questionsCallout.questions_callout_subtitle;
        case 'Product':
          return this.product.callout_subtitle ? this.product.callout_subtitle : this.product.title;
        case 'Collection':
          return this.collection.title;
      }
    },
    description() {
      switch (this.type) {
        case 'Quiz':
          return this.quizCallout.quiz_callout_description;
        case 'Questions':
          return this.questionsCallout.questions_callout_description;
        case 'Product':
          return getTitle(this.product.featured_text);
        case 'Collection':
          return this.collection.description;
      }
    },
    image() {
      switch (this.type) {
        case 'Quiz':
          return this.quizCallout.quiz_callout_image;
        case 'Questions':
          return this.questionsCallout.questions_callout_image;
        case 'Collection':
          return this.collection.featured_image;
      }
    },
    link() {
      switch (this.type) {
        case 'Quiz':
          return '/skincare-quiz';
        case 'Questions':
          return null;
        case 'Product':
          return this.product;
        case 'Collection':
          return this.collection;
      }
    },
    linkText() {
      switch (this.type) {
        case 'Quiz':
          return this.quizCallout.quiz_callout_button_text;
        case 'Questions':
          return this.questionsCallout.questions_callout_button_text;
        case 'Product':
          return 'Buy Now';
        case 'Collection':
          return 'Shop Now';
      }
    }
  },

  methods: {
    ...mapActions([
      'openContactModal'
    ]),
    handleClick() {
      if(this.type == 'Questions') {
        this.openContactModal();
      }
    }
  },

  async fetch() {
    if(this.type == 'Quiz') {
      try {
        const { data } = await this.$apolloProvider.defaultClient.query({
          query: fetchQuizCallout
        });

        const response = data.allGlobals.edges;

        if(response.length) {
          this.quizCallout = response[0].node;
        }
      } catch(err) {
        console.log(err);
      }
    } else if(this.type == 'Questions') {
      try {
        const { data } = await this.$apolloProvider.defaultClient.query({
          query: fetchQuestionsCallout
        });

        const response = data.allGlobals.edges;

        if(response.length) {
          this.questionsCallout = response[0].node;
        }
      } catch(err) {
        console.log(err);
      }
    }
  }
}
