// Mixin used in all link or button components to get correct link and text

import linkResolver from '@/prismic/linkResolver'

import { getTitle } from '@/helpers'

export default {
  props: {
    to: {
      type: [String, Object],
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
    link: {
      type: [String, Object],
      default: null,
    },
    text: {
      type: [String, Array],
      default: '',
    },
  },

  computed: {
    isInternalSliceLink() {
      if (this.link) {
        return this.link.link_type == 'Document' ? true : false
      }
    },
    isInternal() {
      if (this.to) return true

      if (this.link) {
        return this.link._meta || this.link.name || this.isInternalSliceLink
          ? true
          : false
      }
    },
    prismicTo() {
      if (this.link && this.isInternal) {
        const type = this.isInternalSliceLink
          ? this.link.type
          : this.link._meta.type

        if (type == 'product_type') {
          const { shopCategory, _meta } = this.link
          if (shopCategory && _meta) {
            return `/shop/${shopCategory._meta.uid}/${_meta.uid}`
          }
        }

        return linkResolver(
          this.isInternalSliceLink ? this.link : this.link._meta
        )
      }
    },
    prismicHref() {
      if (this.link && !this.isInternal) return this.link.url
      if (this.href) return this.href.url ?? this.href
    },

    computedText() {
      if (!this.isInternal) return this.text

      if (this.text) return this.text

      if (this.link.title) return getTitle(this.link.title)

      if (this.link.name) return this.link.name
    },
  },
}
