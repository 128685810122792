<template>
  <div class="generic">
    <PageTitle :title="error.statusCode" :text="error.message" :isAlone="true">
      <Button href="/" text="Go Back Home" />
    </PageTitle>
  </div>
</template>

<script>
  import { mapState } from 'vuex'

  import PageTitle from '@/components/global/PageTitle'
  import Button from '@/components/global/Button'

  export default {
    name: 'Error',

    layout: 'error',

    components: {
      PageTitle,
      Button,
    },

    props: ['error'],

    computed: {
      ...mapState({
        globalMetaTitle: state => state.globalData.meta_title,
      }),
    },

    head() {
      const siteName = this.globalMetaTitle
        ? this.globalMetaTitle
        : this.$config.siteName

      return {
        title: `${this.error.statusCode} | ${siteName}`,
      }
    },
  }
</script>
