export default {
  data() {
    return {
      isSubmitting: false,
      showFeedback: false,
      submitState: null,
      submitMessage: null,
      defaultButtonText: 'Submit',
    }
  },

  computed: {
    buttonText() {
      if (this.isSubmitting) {
        return 'Submitting...'
      } else {
        return this.defaultButtonText
      }
    },
  },

  methods: {
    handleFormFeedback(type, message) {
      this.isSubmitting = false
      this.showFeedback = true
      this.submitState = type
      this.submitMessage = message
    },
  },
}
